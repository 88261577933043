import * as React from "react"
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image";
import '../styles/styles.scss'
import {Parallax} from "react-scroll-parallax";

const MayoristasPage = () => {
    return (
        <Layout>

            <div className={'cover-simple blue serviciosadmin'}>
                <div className="item">
                    <div className={'sliderleft'}>
                        <p className={'especial3 especialservicios'}>Mayoristas</p>
                        <h3 className={'h3servicios'}>Hardware y software a tu disposición</h3>
                        <p className={'contenido1 contenidoservicios'}>Trabajamos con mayoristas y todo su inventario de
                            productos y servicios lo ponemos disponible para cubrir tus requerimientos y
                            necesidades.</p>
                    </div>
                    <div className={'sliderright'}>
                        <StaticImage src="../images/mayoristas-right.png" alt="T.I.O. ALEMAR"/>
                    </div>
                </div>
            </div>

            <div className={'image-info image-visible'}>
                <Parallax speed={-10} className={'bloqueleft40 left float'}>
                    <StaticImage className={'img'} src="../images/Hardware.png" alt="Servicios ALEMAR"/>
                </Parallax>
                <Parallax speed={10} className={'bloqueright2servicios right mayoristas'}>
                    <h4 className={'h4servicios'}>Hardware.</h4>
                    <ul>
                        <li className={'especial3'}>Equipos</li>
                        <li className={'especial3'}>Laptops</li>
                        <li className={'especial3'}>PC's</li>
                        <li className={'especial3'}>Servidores</li>
                    </ul>
                    <button className="btn-push-black"><span>Me interesa</span></button>
                </Parallax>
            </div>

            <div className={'image-info inverted image-visible'}>
                <Parallax speed={10} className={'left'}>
                    <h4 className={'h4servicios'}>Software.</h4>
                    <ul>
                        <li className={'especial3'}>Licencias Microsoft</li>
                        <li className={'especial3'}>Antivirus</li>
                        <li className={'especial3'}>Soluciones de firmas electrónicas</li>
                    </ul>
                    <button className="btn-push-black"><span>Me interesa</span></button>
                </Parallax>
                <Parallax speed={-10} className={'right'}>
                    <StaticImage className={'img'} src="../images/software-float.png" alt="Servicios ALEMAR"/>
                </Parallax>
            </div>

            <div className={'logossocios'}>
                <ul className={'wide'}>
                    <li
                        data-sal="slide-up"
                        data-sal-delay={"100"}>
                        <StaticImage className={'img'} objectFit={'contain'}
                                     src="../images/logospartners/09-lexmark-logo.png"
                                     alt="Lexmark logo"/></li>
                    <li
                        data-sal="slide-up"
                        data-sal-delay={"100"}>
                        <StaticImage className={'img'} objectFit={'contain'}
                                     src="../images/logospartners/06-dell-logo.png"
                                     alt="Dell logo"/></li>
                    <li
                        data-sal="slide-up"
                        data-sal-delay={"100"}>
                        <StaticImage className={'img'} objectFit={'contain'}
                                     src="../images/logospartners/08-cisco-logo.png"
                                     alt="Cisco logo"/></li>
                    <li
                        data-sal="slide-up"
                        data-sal-delay={"100"}>
                        <StaticImage className={'img'} objectFit={'contain'}
                                     src="../images/logospartners/01-exel-logo.png"
                                     alt="Lexmark logo"/></li>
                    <li
                        data-sal="slide-up"
                        data-sal-delay={"200"}>
                        <StaticImage className={'img'} objectFit={'contain'}
                                     src="../images/logospartners/02-team-logo.webp"
                                     alt="AWS Logo"/></li>
                    <li
                        data-sal="slide-up"
                        data-sal-delay={"400"}>
                        <StaticImage className={'img'} objectFit={'contain'}
                                     src="../images/logospartners/04-hellosign-logo.png"
                                     alt="M Files Logo"/></li>
                    <li
                        data-sal="slide-up"
                        data-sal-delay={"500"}>
                        <StaticImage className={'img'} objectFit={'contain'}
                                     src="../images/logospartners/05-docusign-logo.png"
                                     alt="DOQSOFT Logo"/></li>
                    <li
                        data-sal="slide-up"
                        data-sal-delay={"100"}>
                        <StaticImage className={'img'} objectFit={'contain'}
                                     src="../images/logospartners/07-hp-logo.png"
                                     alt="Hp logo"/></li>
                </ul>
            </div>

        </Layout>
    )
}

export default MayoristasPage

export const Head = () => <title>T.I.O. ALEMAR | Aliados</title>
